<template>
  <div class="trade-page mobile-trade-page container-fluid">
    <div class="general-box container">
      <div class="wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="left-wrap-col trade-graphic-panel-box">
                <TradeGraphicPanel />
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="right-wrap-col trade-pairs-box"
                :class="{ active: showPairStats }"
              >
                <TradePairs />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TradeGraphicPanel from './TradeGraphicPanel.vue'
import TradePairs from './TradePairs.vue'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('App', ['showPairStats']),
  },

  components: {
    TradeGraphicPanel,
    TradePairs,
  },
}
</script>

<style lang="scss" src="./styles/src.scss"></style>
